var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category",style:({backgroundColor: _vm.category === 'IRRELEVANT'
? 'var(--brand-accent-neutral-alt)':
_vm.category === 'POTENTIAL' ? 'var(--color-danger)' :
_vm.category === 'CONFIRMED' ? 'var(--brand-color)' : 
_vm.category === true ? 'var(--color-success)' : ''
})},[_c('img',{attrs:{"src":require('@/assets/icons/' + 
(  _vm.category === 'POTENTIAL' ? 'warn' :
  _vm.category === 'CONFIRMED' || _vm.category === true ? 'check' : '') + '.svg'),"alt":"status"}})])}
var staticRenderFns = []

export { render, staticRenderFns }