import Badge from "@/components/badge";
import Button from "@/components/button";
import Categorized from "@/components/categorized";
import Info from "@/components/info";
import RestoreBtn from "@/components/restore-btn";
import SetStatus from "@/containers/set-status";
import {getIcon} from "@/utils/functions";
import {mapGetters} from "vuex";

export default {
    name: "neo-dark-web",
    components: {
        "neo-info": Info,
        "neo-badge": Badge,
        "neo-button": Button,
        "neo-restore-btn": RestoreBtn,
        "neo-set-status": SetStatus,
        "neo-categorized": Categorized,
    },
    props: ["content", "setStatus", "restore", "tab"],
    data() {
        return {
            controls: {
                status: this.content.status,
            },
        };
    },
    computed: {
        ...mapGetters(["getReadOnlyMode"]),
    },
    mounted() {},
    methods: {
        getIcon,
        popIrrelevantData() {
            delete this.content.confidence_score;
            this.$emit("dataChanges", {...this.content, status: null, tab: this.tab});
        },
        onStatChanges(status) {
            this.$emit("dataChanges", {...this.content, status});
        },
    },
};
