import ExtLink from "@/components/ext-link";
import {dashedCase} from "@/utils/functions";

export default {
    name: "neo-info",
    components: {
        "neo-ext-link": ExtLink,
    },
    props: ["type", "content", "flip", "showIcon"],
    data() {
        return {};
    },
    computed: {
        dashedType() {
            return dashedCase(this.type);
        },
    },
    mounted() {},
    methods: {},
};
